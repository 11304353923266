//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getQiniuApi, registerApi, getCodeApi} from "../api";

export default {
  name: 'Register',
  data () {
    let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('register.confirmPlaceholder')))
        } else if (value !== this.form.password) {
          callback(new Error(this.$t('register.confirmCheck')))
        } else {
          callback();
        }
      }
    let checkMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('register.telPhone')))
      } else if (!/^((0\d{2,3}-\d{7,8})|(\d{7,8})|(1[345789]\d{9}))$/.test(value)) {
        callback(new Error(this.$t('register.telPhoneCheck')))
      } else {
        callback();
      }
    }
    let checkPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('register.phonePlaceholder')))
      } else if (!/^(1[345789]\d{9})$/.test(value)) {
        callback(new Error(this.$t('register.phoneCheck')))
      } else {
        callback();
      }
    }
    return {
      action: '',
      qiuData: {},
      form: {
        name: '',
        password: '',
        confirm: '',
        nameZh: '',
        nameEn: '',
        country: '中国',
        address: '',
        contact: '',
        phone: '',
        mobile: '',
        liscen: '',
        logo: '',
        indexPic: '',
        desZh: '',
        desEn: '',
        email: '',
        emailCode: ''
      },
      isCheck: false,
      isShowTime: false,
      time: 60,
      rules: {
        name: [{required: true,message: this.$t('register.namePlaceholder'), trigger: 'blur'}],
        password: [{required: true,message: this.$t('register.passwordPlaceholder'), trigger: 'blur'}],
        confirm: [{ required: true, message: this.$t('register.confirmPlaceholder') }, { validator: validatePass2, trigger: 'blur' }],
        nameZh: [{required: true,message: this.$t('register.nameZhPlaceholder'), trigger: 'blur'}],
        nameEn: [{required: true,message: this.$t('register.nameEnPlaceholder'), trigger: 'blur'}],
        contact: [{required: true,message: this.$t('register.contactPlaceholder'), trigger: 'blur'}],
        address: [{required: true,message: this.$t('register.addressPlaceholder'), trigger: 'blur'}],
        phone: [{ required: true, message: this.$t('register.phonePlaceholder') }, { validator: checkPhone, trigger: 'blur' }],
        mobile: [{ required: true, message: this.$t('register.telPhonePlaceholder') }, { validator: checkMobile, trigger: 'blur' }],
        liscen: [{required: true,message: this.$t('register.licensePlaceholder'), trigger: 'blur'}],
        logo: [{required: true,message: this.$t('register.logoPlaceholder'), trigger: 'blur'}],
        desZh: [{required: true,message: this.$t('register.introduceZhPlaceholder'), trigger: 'blur'}],
        desEn: [{required: true,message: this.$t('register.introduceEnPlaceholder'), trigger: 'blur'}],
        email: [{required: true,message: this.$t('register.emailPlaceholder'), trigger: 'blur'},{type: 'email',message: this.$t('register.emailCheck'), trigger: 'blur'}],
        emailCode: [{required: true,message: this.$t('register.codePlaceholder'), trigger: 'blur'}]
      }
    }
  },
  created() {
    this.getQiuData()
  },
  methods: {
    async getCode () {
      try {
        const res = await getCodeApi({email: this.form.email})
        console.log(res)
        this.$message({
          type: 'success',
          message: res.data,
          offset: 300
        })
        this.sendCode()
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.data,
          offset: 300
        })
      }
    },
    async getQiuData() {
      try {
        const res = await getQiniuApi()
        this.action = res.host
        this.domain = res.domain
        this.qiuData = {
          token: res.token,
          key: '',
          data: {}
        }
        console.log(this.action, this.domain, this.qiuData)
      } catch (e) {
        console.log(e)
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          this.$message({
            type: 'error',
            message: this.$t('tips.formError'),
            offset: 300
          })
          return false
        }
      })
    },
    async submit() {
      if (!this.isCheck) {
        this.$message({
          type: 'warning',
          message: '请阅读并勾选中国联合展台用户协议',
          offset: 300
        })
        return
      }
      try {
        console.log(res)
        const res = await registerApi({
          username: this.form.name,
          password: this.form.password,
          enpassword: this.form.confirm,
          company_name: this.form.nameZh,
          en_company_name: this.form.nameEn,
          company_area: this.form.country,
          company_place: this.form.address,
          nickname: this.form.contact,
          phone: this.form.phone,
          land_num: this.form.mobile,
          logo: this.form.logo,
          license: this.form.liscen,
          homepage_img: this.form.indexPic,
          company_desc: this.form.desZh,
          en_company_desc: this.form.desEn,
          email: this.form.email,
          auth_code: this.form.emailCode,
        })
        this.resetForm('ruleForm')
        setTimeout(() => {
          this.$router.push({ name: 'success', query: {type: 'register'} })
        },2000)
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.data,
          offset: 300
        })
        console.log(e)
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendCode () {
      let timer = ''
      this.isShowTime = true
      timer = setInterval( () => {
        if (this.time <= 0) {
          clearInterval(timer)
          this.isShowTime = false
          this.time = 60
        } else {
          this.time--
        }
      }, 1000)
    },
    loginSuccess(response, file, fileList) {
      this.form['logo'] = this.domain + response.key
    },
    licenseSuccess(response, file, fileList) {
      this.form['liscen'] = this.domain + response.key
    },
    indexSuccess(response, file, fileList) {
      this.form['indexPic'] = this.domain + response.key
    },
    beforeAvatarUpload (file) {
      console.log()
      this.qiuData.key = new Date().getTime() + file.name
      this.qiuData.data = file
    },
    openNewWindow () {
      window.open('https://www.chinapavilion.com.cn/agreement.html')
    },
  }
}
